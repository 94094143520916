body {
  background-color: #000 !important;
}

hr {
  color: #fff;
  border-width: 4px;
  border: 4px solid #fff;
  width: 100%;
}

.section-1 button {
  background-color: #c06d00;
  width: 100%;
}

.section1-btn {
  text-shadow: 2px 2px 5px rgb(246, 241, 241);
  font-size: 80px;
  font-family: 'Times New Roman', Times, serif;
  border-radius: 35px;
}

.board {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  font-size: 33px;
}

.monthYrTbl {
  text-transform: uppercase;
}







.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}


/* .game-container {
  width: 1550px;
} */

.game_column {
  border-radius: 15px 0px 15px 0px;
  /* background: linear-gradient(to bottom, #1000ff, #ffffffd4); */
  background: linear-gradient(45deg, #9d9696, #ac7500, #a30fa3);
  border: outset #00030e 3px;
  padding: 0.5rem 0rem;
  /* width: 98%; */
  margin: 0px auto;
}

.game-list {
  background-color: black !important;
}

.game_column button {
  width: 40px;
  height: 26px;
  border-radius: 50px;
  color: red;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px;
  text-align: center;
  align-items: center;
  padding: 0rem;
}

.custom {
  padding-left: 36%;
}

.column {
  /*width: calc(50% - 5px); */
  /* max-width: 663px; */
  /* height: 120px; */
  /* border: 1px solid #ddd; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  box-sizing: border-box;
}

.column-gap {
  width: 10px;
  /* 10px gap between columns */
}

.column-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 0.3rem 0px;
  /* Minimum gap between lines */
}

.text-line {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-and-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.image {
  width: 56px;
  height: 11px;
  background-color: gray;
}

.button-left,
.button-right {
  width: 40px;
  height: 26px;
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-right {
  color: red;
}

/*===== ScrollBar 1 =======*/

#scrollbar1::-webkit-scrollbar {
  width: 1px !important;
  height: 0.5rem;
}

#scrollbar1::-webkit-scrollbar-track {
  border-radius: 8px !important;
  background-color: transparent;
  border: 1px solid #cacaca !important;
}

#scrollbar1::-webkit-scrollbar-thumb {
  border-radius: 8px;

  background-color: #cacaca;
}

/*===== ScrollBar 1 end =======*/


/* Styling for MonthlyTable component */
/* .table-responsive {
  overflow-x: auto;
  white-space: nowrap;
} */

/* .table-responsive #scrollbar1 {
  font-family: Arial, sans-serif;
} */

/* Style the header row */
/* .ant-table-thead>tr>th {
  background-color: red !important;
  color: white !important;
  height: 30px;
  line-height: 30px;
} */

/* Style the first column of the table body and header */
.ant-table-tbody>tr>td:first-child,
.ant-table-thead>tr>th:first-child {
  background-color: #1A1A1A;
  color: white;
  padding: 0rem 1rem !important;
}

/* Style the rest of the table body */
.ant-table-tbody>tr>td {
  background-color: #D8BFD8;
  color: black;
  border: 1px solid #b39c9c;
  height: 30px;
  line-height: 30px;
  /* Ensures text is vertically centered */
}

/* Style the vertical and horizontal lines */
.ant-table-cell {
  border-right: 1px solid black;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid black;
  height: 30px;
  /* line-height: 30px; */
  /* Ensures text is vertically centered */
}

.ant-table-cell {
  padding: unset !important;
  background-color: red;
}

.ant-table-row:nth-child(odd) {
  background-color: red;
}

/* Links section styling */
.col-12.link.section {
  margin-top: 20px;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.links .col {
  flex: 1 0 21%;
  /* Adjust this value based on the number of columns */
  margin: 5px 0;
}

.links .col a {
  color: white;
  text-decoration: none;
}

.ant-table-thead>tr>td,
.ant-table-thead th {
  background-color: #530153 !important;
  color: white !important;
  /* border: 1px solid #e7aa26; */
  border-radius: 0px !important;
  padding: 0rem 1rem !important;
}

/* 
.table tr {
  padding: 0rem 1rem !important;
} */

.ant-table-tbody>tr>td:first-child:hover {
  background-color: red !important;
  color: white !important;
}

.ant-table-tbody>tr:hover>td:first-child {
  background-color: red !important;
  color: white !important;
}

.ant-table-content th {
  padding: 9px 1rem !important;
}

.ant-table-content td,
.ant-table-content tr,
.ant-table-content th {
  text-align: center !important;
  text-wrap: nowrap;
  border-right: 1px solid #ccc !important;
  /* vertical-align: middle !important; */
}


/* =====footer css===== */
#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: bold;
}

#go-button {
  background-color: rgb(24, 109, 237);
  color: white;
  cursor: pointer;
}

#go-button:hover {
  background-color: navy;
}


/* refresh button */
.refreshButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border: #cd0404 4px solid;
  background: #fff;
  color: Red;
  height: auto;
  padding: 8px;
  font-style: italic;
  border-radius: 10px;
  font-weight: bold;
  color: #000;
}

/* footer button styling */
.footerButton button {
  background:  #b97f00;
  width: 100%;
  border-radius: 1rem;
  border: 0px;
  color: #ff0000;
  font-weight: bolder;
}

.footerButton .last-button {
  width: 32%;
}


/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #837e7e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #837e7e;
  border: 1px solid;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 10px;
  background-color: #c1c6c2;
}


/* ======= react toas ===== */
.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  2% {
    opacity: 0;

    /* Text color when visible */
  }

  30% {
    opacity: 0.1;
    color: #72ff07;
    /* Text color when hidden */
  }

  60% {
    opacity: 0.5;
    color: #e6ff07
      /* Text color when hidden */
  }

  100% {
    opacity: 1;
    color: #ffc107;
    /* Text color when visible */
  }
}

.blinking-text {
  animation: blink 1.5s infinite;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}


/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #e67e22;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  /* background-color: #fef9e7; */
  background: linear-gradient(45deg, #FFFFFF, #98FF98, #D8BFD8);
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  background-color: #1A1A1A;
  padding: 0.5rem;
  border-radius: 0.25rem;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #1A1A1A;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}